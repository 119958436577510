<template>
  <div
    class="list-box"
    style="
      padding: 10px 15px 10px 15px;
      border: 1px solid #dedede;
      overflow: hidden;
      align-items: center;
      justify-content: space-between;
      border-radius: 8px;
    "
  >
    <div style="background: #f8f8f8; width: 100px; height: 100px">
      <img
        class="list-img"
        style="max-width: 100%; max-height: 100%; width: 100%; height: 100%"
        :src="resolveImage(defaultPicture)"
        alt=""
      >
    </div>
    <div
      class="list-content"
      style="display: flex; justify-content: space-between !important"
    >
      <div>
        <p class="title font-bold leading-tight">
          {{ name }}
        </p>
        <!-- <p class="tips">{{ description }}</p> -->
        <div
          v-if="stars !== null"
          class="shopProductDetails__right__stars"
        >
          <star-rating
            :rating="Number(stars)"
            :border-width="3"
            :read-only="true"
            :show-rating="false"
            :star-size="10"
            inactive-color="#EBEBEB"
            active-color="#f6a42c"
            border-color="#EBEBEB"
            :star-points="[
              23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46,
              19, 31, 17,
            ]"
            :rounded-corners="true"
            class="shopProductDetails__right__stars__star"
            style="display: block !important; width: 100% !important"
          />
        </div>
        <p class="price">
          <small class="text-xs">&pound;</small>{{ formatPrice(price) }}
        </p>
      </div>
      <!-- <div class="mt-auto mx-[5px]">
        <p class="text-[0.55rem]">Warning: Contains small parts.</p>
        <p class="text-[0.55rem]">Adult supervision recommended.</p>
      </div> -->
      <!-- <p class="time">&pound;{{ formatPrice(price) }}</p> -->
    </div>
  </div>
</template>

<script>
  // import AppProductProgressBar from "@/components/AppProductProgressBar";
  // import AppModal from "@/components/AppModal";
  // import AppButton from "@/components/AppButton";
  // import AppShopProductDetails from "@/components/AppShopProductDetails";
  import { mapGetters } from "vuex";

  import config from "../config";

  export default {
    components: {
      // AppProductProgressBar,
      // AppModal,
      // AppShopProductDetails,
      // AppButton,
    },
    props: {
      name: {
        type: String,
        default: "",
      },
      description: {
        type: String,
        default: "",
      },
      price: {
        // type: Number,
        // default: 0
      },
      progressValue: {
        // type: Number,
        // default: 0
      },
      stars: {
        type: Number,
        default: 0,
      },
      savingsAdded: {
        // type: String,
        // default: ''
      },
      cashYouMoreNeed: {
        // type: Number,
        // default: 0
      },
      defaultPicture: {
        type: String,
        default: "",
      },
      images: {
        type: Array,
        default: () => [],
      },
      productId: {
        type: Number,
        default: 0,
      },
      givenStars: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        goalModal: false,
      };
    },
    computed: {
      ...mapGetters(["getSpendWalletBalance"]),
    },
    methods: {
      resolveImage(src) {
        return config.HOST + src;
      },
      openGoalDetailsModal() {
        this.goalModal = true;
      },
      closeGoalDetailsModal() {
        this.goalModal = false;
        this.$store.state.readMore = false;
        this.getSpendWalletBalance;
      },
      getProgressBarText() {
        if (this.progressValue == 100) return "You can buy this";
        else return "You need £" + this.cashYouMoreNeed + " more";
      },
    },
  };
</script>

<style lang="scss" scoped>
  .list_ul {
    list-style: none;
    overflow: hidden;
  }

  .list-item {
    position: relative;
    height: 4rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
  }

  .list-item[data-type="0"] {
    transform: translate3d(0, 0, 0);
  }

  .list-item[data-type="1"] {
    transform: translate3d(-5rem, 0, 0);
  }

  .list-box {
    padding: 1.2rem;
    background: #fff;
    display: flex;
    // align-items: center;
    // -webkit-box-sizing: border-box;
    // box-sizing: border-box;
    // justify-content: flex-end;
  }

  .list-item .list-img {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    object-fit: contain;
  }

  .list-item .list-content {
    padding: 0.25rem 0 0.25rem 0.5rem;
    position: relative;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
  }

  .list-item .title {
    font-size: 14px;
    // line-height: 19px;
    -webkit-line-clamp: 2;
    max-height: 37px;
    margin-bottom: 0;
    text-rendering: optimizeLegibility;
    color: #7b625b;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin: 5px;
  }

  .list-item .tips {
    display: block;
    overflow: hidden;
    font-size: 0.6rem;
    color: #999;
    line-height: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
  }

  .list-item .time {
    display: block;
    font-size: 0.6rem;
    position: absolute;
    right: 0;
    top: 0.25rem;
    color: var(--main);
    font-weight: 600;
  }

  .list-item .price {
    display: block;
    font-size: 19px;
    color: #36d5f2;
    font-weight: 700;
    margin: 5px;
  }

  .list-item .delete {
    width: 5rem;
    height: 4rem;
    background: #ff4949;
    font-size: 0.85rem;
    color: #fff;
    text-align: center;
    line-height: 4rem;
    position: absolute;
    top: 0;
    right: -5rem;
  }
</style>
