<template>
    <div>
        <header>
            <div
                style="
                    text-align: center;
                    color: var(--main);
                    line-height: 28px;
                    padding: 40px 40px 20px 40px;
                "
            >
                <h1 style="font-size: 25px; font-weight: 800">Store</h1>
                <p style="font-size: 12px; font-weight: 600">
                    Buy for yourself or for the loved ones!
                </p>
            </div>
        </header>
        <div
            class="missionDays"
            style="margin-bottom: 20px; padding: 0 10px; background: transparent"
        >
            <!-- <AppCategoryItem
        name="All"
        bgColor="#36d5f2"
        :isActive="0 == selectedCategoryId"
        @click.native="selectCategory(0, true)"
      /> -->
            <AppCategoryItem
                v-for="(category, index) in takeCategories"
                :key="index"
                :name="category.name"
                :icon-src="category.image_link"
                :bg-color="category.bgColor"
                :is-active="category.id == selectedCategoryId"
                @click.native="selectCategory(category.id, false)"
            />
        </div>

        <div style="padding: 0 10px; margin-bottom: 30px">
            <div
                class="searchBar border-2 border-[#dedede]"
                style="border-radius: 100px"
            >
                <input
                    v-model="search"
                    style="padding-top: 10px; padding-bottom: 10px"
                    placeholder="Search..."
                />
                <svg
                    version="1.1"
                    class="fa-search"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    width="20"
                    height="20"
                    viewBox="0 0 124.524 124.524"
                    style="enable-background: new 0 0 124.524 124.524"
                    xml:space="preserve"
                >
                    <g>
                        <path
                            fill="#7a615a59"
                            d="M51,102.05c10.5,0,20.2-3.2,28.3-8.6l29.3,29.3c2.301,2.3,6.101,2.3,8.5,0l5.7-5.7c2.3-2.3,2.3-6.1,0-8.5L93.4,79.35
		c5.399-8.1,8.6-17.8,8.6-28.3c0-28.1-22.9-51-51-51c-28.1,0-51,22.9-51,51C0,79.149,22.8,102.05,51,102.05z M51,20.05
		c17.1,0,31,13.9,31,31c0,17.1-13.9,31-31,31c-17.1,0-31-13.9-31-31C20,33.95,33.9,20.05,51,20.05z"
                        />
                    </g>
                </svg>
                <!-- <div class="spinner"><i class="fa fa-spinner"></i></div> -->
            </div>
        </div>

        <div style="padding: 0 10px">
            <!-- <div
        v-if="Object.keys(brandsGetter).length > 0"
        style="margin-bottom: 30px"
      >
        <div style="margin-bottom: 15px; font-size: 16px">Filter by brand</div>
        <div class="brandScroll">
          <div
            v-for="brand in brandsGetter"
            :key="brand.id"
            class="brandScroll__item"
            :class="{ activeBrand: selectedBrand === brand.id }"
            @click="selectBrand(brand.id)"
          >
            <img
              width="100%"
              height="50"
              style="object-fit: contain; margin-bottom: 5px"
              :src="resolveImage(brand.image)"
            />
            <div style="font-size: 12px">{{ brand.name }}</div>
          </div>
        </div>
        <div
          v-if="clearFilters"
          style="
            text-align: right;
            color: var(--red);
            margin-top: 10px;
            font-size: 12px;
          "
          @click="clearFiltersMethod"
        >
          Clear filters
        </div>
      </div> -->

            <div
                v-if="isLoadingMore"
                class="fixed w-full h-full flex items-center justify-center top-0 left-0 right-0 bottom-0 z-50"
                style="z-index: 9999"
            >
                <div
                    class="bg-[#01a4c0] text-white opacity-60 rounded-lg w-32 flex items-center p-2 justify-center"
                >
                    <svg
                        class="animate-spin -ml-1 mr-3 h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <circle
                            class="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                        />
                        <path
                            class="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                    </svg>
                    Loading
                </div>
            </div>

            <div v-if="storeProducts.length > 0">
                <div v-for="product in storeProducts" :key="product.id">
                    <ul class="list_ul" style="padding: 0 10px">
                        <li class="list-item shadow-sm" data-type="0">
                            <div @click="openDetailsPage(product)">
                                <MobileStoreItemNew
                                    :name="product.name"
                                    :product-id="product.id"
                                    :price="Number(product.price).toFixed(2)"
                                    :default-picture="
                                        product.default_picture_link
                                    "
                                    :images="getProductImages(product)"
                                    :progress-value="
                                        Number(product.percent).toFixed(2)
                                    "
                                    :description="product.description"
                                    :stars="product.star"
                                    :cash-you-more-need="
                                        Number(
                                            product.cash_u_need_more
                                        ).toFixed(2)
                                    "
                                    :given-stars="product.myStar"
                                    :has-button="true"
                                />
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div v-else class="recent_container" style="padding: 0 10px">
                <div
                    class="shadow"
                    style="
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        height: 90px;
                        margin-bottom: 8px;
                        display: flex;
                        align-items: center;
                        background: white;
                        padding: 15px;
                        border-radius: 15px;
                        position: relative;
                        overflow: hidden;
                    "
                >
                    <div
                        style="
                            text-align: center;
                            color: var(--secondary);
                            font-size: 12px;
                            font-weight: 500;
                        "
                    >
                        No product that matches the search criteria
                    </div>
                </div>
            </div>

            <!-- <div v-for="commentIndex in productsToShow" :key="commentIndex - 1">
        <ul
          v-if="commentIndex - 1 < filteredBrands.length"
          class="list_ul"
          style="padding: 0 10px"
        >
          <li class="list-item shadow" data-type="0">
            <div @click="openDetailsPage(filteredBrands[commentIndex - 1])">
              <MobileStoreItemNew
                :name="filteredBrands[commentIndex - 1].name"
                :productId="filteredBrands[commentIndex - 1].id"
                :price="
                  Number(filteredBrands[commentIndex - 1].price).toFixed(2)
                "
                :defaultPicture="
                  filteredBrands[commentIndex - 1].default_picture_link
                "
                :images="getProductImages(filteredBrands[commentIndex - 1])"
                :progressValue="
                  Number(filteredBrands[commentIndex - 1].percent).toFixed(2)
                "
                :description="filteredBrands[commentIndex - 1].description"
                :stars="filteredBrands[commentIndex - 1].star"
                :cashYouMoreNeed="
                  Number(
                    filteredBrands[commentIndex - 1].cash_u_need_more
                  ).toFixed(2)
                "
                :givenStars="filteredBrands[commentIndex - 1].myStar"
                :has-button="true"
              />
            </div>
          </li> -->

            <!-- <MobileStoreItem
            :name="filteredBrands[commentIndex - 1].name"
            :productId="filteredBrands[commentIndex - 1].id"
            :price="Number(filteredBrands[commentIndex - 1].price).toFixed(2)"
            :defaultPicture="
              filteredBrands[commentIndex - 1].default_picture_link
            "
            :images="getProductImages(filteredBrands[commentIndex - 1])"
            :progressValue="
              Number(filteredBrands[commentIndex - 1].percent).toFixed(2)
            "
            :description="filteredBrands[commentIndex - 1].description"
            :stars="filteredBrands[commentIndex - 1].star"
            :cashYouMoreNeed="
              Number(filteredBrands[commentIndex - 1].cash_u_need_more).toFixed(
                2
              )
            "
            :givenStars="filteredBrands[commentIndex - 1].myStar"
            :has-button="true"
            @click.native="openDetailsPage(filteredBrands[commentIndex - 1])"
          /> -->
            <!-- </ul>
      </div> -->
            <!-- <div
        v-if="
          productsToShow < filteredBrands.length ||
          filteredBrands.length > productsToShow
        "
      >
        <AppButton
          text="Show more products"
          color="red"
          :mini="true"
          @click="productsToShow += 5"
          style="
            margin: 40px auto 20px auto !important;
            width: 70% !important;
            display: block !important;
            font-size: 16px;
          "
        />
      </div> -->

            <!-- <div class="text-xs text-center mb-3 mt-6">
                Page {{ pagination.current_page }} out of
                {{ pagination.total_pages }}
            </div>
            <div class="flex justify-center">
                <button
                    @click="paginateBack()"
                    :disabled="pagination.current_page == 1"
                    class="
                        border
                        text-xs
                        border-teal-500
                        text-teal-500
                        block
                        rounded-sm
                        font-bold
                        py-2
                        px-6
                        mr-2
                        flex
                        items-center
                        hover:bg-[#0ba5c1] hover:text-white
                    "
                    :class="
                        pagination.current_page == 1
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                    "
                >
                    <svg
                        class="h-3 w-3 mr-2 fill-current"
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="-49 141 512 512"
                        style="enable-background: new -49 141 512 512"
                        xml:space="preserve"
                    >
                        <path
                            id="XMLID_10_"
                            d="M438,372H36.355l72.822-72.822c9.763-9.763,9.763-25.592,0-35.355c-9.763-9.764-25.593-9.762-35.355,0 l-115.5,115.5C-46.366,384.01-49,390.369-49,397s2.634,12.989,7.322,17.678l115.5,115.5c9.763,9.762,25.593,9.763,35.355,0 c9.763-9.763,9.763-25.592,0-35.355L36.355,422H438c13.808,0,25-11.193,25-25S451.808,372,438,372z"
                        ></path>
                    </svg>
                    Back
                </button>
                <button
                    @click="paginateNext()"
                    :disabled="
                        pagination.current_page == pagination.total_pages
                    "
                    class="
                        border
                        text-xs
                        border-teal-500
                        text-teal-500
                        block
                        rounded-sm
                        font-bold
                        py-2
                        px-6
                        mr-2
                        flex
                        items-center
                        hover:bg-[#0ba5c1] hover:text-white
                    "
                    :class="
                        pagination.current_page == pagination.total_pages
                            ? 'opacity-50 cursor-not-allowed'
                            : ''
                    "
                >
                    Next
                    <svg
                        class="h-3 w-3 ml-2 fill-current"
                        clasversion="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="-49 141 512 512"
                        style="enable-background: new -49 141 512 512"
                        xml:space="preserve"
                    >
                        <path
                            id="XMLID_11_"
                            d="M-24,422h401.645l-72.822,72.822c-9.763,9.763-9.763,25.592,0,35.355c9.763,9.764,25.593,9.762,35.355,0
            l115.5-115.5C460.366,409.989,463,403.63,463,397s-2.634-12.989-7.322-17.678l-115.5-115.5c-9.763-9.762-25.593-9.763-35.355,0
            c-9.763,9.763-9.763,25.592,0,35.355l72.822,72.822H-24c-13.808,0-25,11.193-25,25S-37.808,422-24,422z"
                        />
                    </svg>
                </button>
            </div> -->
        </div>
    </div>
</template>

<script>
import AppCategoryItem from "../../components/AppCategoryItem";
import MobileStoreItemNew from "../../components/MobileStoreItemNew";
// import MobileStoreItem from "@/components/MobileStoreItem";
// import AppButton from "@/components/AppButton";
import config from "../../config";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import status from "../../utils/statusCode";

export default {
    components: {
        AppCategoryItem,
        // MobileStoreItem,
        MobileStoreItemNew,
        // AppButton,
    },
    data() {
        return {
            productsToShow: 5,
            spendWallet: 3,
            section: "shop", //swipe
            search: null,
            selectedCategoryId: null,
            filtered_products: [],
            all_products: [],
            selectedProduct: [],
            selectedProductCurrentIndex: 0,
            categories: [],
            firstView: true,
            products: [],
            storeProducts: [],
            error: "",
            isBrandsShown: false,
            selectedBrand: 0,
            clearFilters: false,
            showAllProducts: true,
            pagination: {},
            limit: 10,
            busy: false,
            loading: false,
            scrolledToBottom: true, // make sure it's set to true
            isLoadingMore: false,
        };
    },
    mounted() {
        this.scroll();
    },
    methods: {
        ...mapActions([
            "fetchStore",
            "fetchOcassions",
            "setupUser",
            "fetchBrandsAction",
            "fetchProfile",
            "setupUser",
            "TOAST",
        ]),
        scroll() {
            window.onscroll = () => {
                let bottomOfWindow =
                    Math.max(
                        window.pageYOffset,
                        document.documentElement.scrollTop,
                        document.body.scrollTop
                    ) +
                        window.innerHeight ===
                    document.documentElement.offsetHeight;

                if (bottomOfWindow) {
                    if (this.scrolledToBottom) {
                        if (
                            this.pagination.current_page ==
                            this.pagination.total_pages
                        ) {
                            this.scrolledToBottom = false;
                        }
                        this.isLoadingMore = true;
                        this.fetchStoreByCategoryId(
                            this.selectedCategoryId,
                            this.pagination.current_page + 1
                        );
                    }
                }
            };
        },
        paginateNext() {
            this.fetchStoreByCategoryId(
                this.selectedCategoryId,
                this.pagination.current_page + 1
            );
        },
        paginateBack() {
            this.fetchStoreByCategoryId(
                this.selectedCategoryId,
                this.pagination.current_page - 1
            );
        },
        openDetailsPage(product) {
            const productObj = {
                from: "shop",
                selectedSection: "details",
                name: product.name,
                productId: product.id,
                description: product.description,
                price: Number(product.price).toFixed(2),
                stars: Number(product.star),
                defaultPicture: product.default_picture_link,
                images: this.getProductImages(product),
                cashYouMoreNeed: product.cash_u_need_more,
                progressValue: "Number(product.percent).toFixed(2)",
                givenStars: product.myStar,
            };

            console.log(product);

            localStorage.setItem("storeProduct", JSON.stringify(productObj));

            this.$router.replace("product_details_shop");
        },
        resolveImage(src) {
            return config.HOST + src;
        },
        showBrands() {
            this.fetchBrandsAction();
            this.isBrandsShown = true;
            this.clearFilters = true;
        },
        clearFiltersMethod() {
            this.isBrandsShown = false;
            this.clearFilters = false;
            this.selectedBrand = 0;
        },
        selectBrand(id) {
            this.selectedBrand = id;
            this.clearFilters = true;
        },
        getProductImages(product) {
            if (product == null) return [];
            var pictures = [];
            if (product.default_picture_link)
                pictures[0] = product.default_picture_link;
            if (product.picture1_link) pictures[1] = product.picture1_link;
            if (product.picture2_link) pictures[2] = product.picture2_link;
            if (product.picture1_link) pictures[3] = product.picture2_link;
            return pictures;
        },
        getActionEventValue(value) {
            this.section = value;
        },
        filterItemsByKey() {
            var products =
                this.$store.state.categories[this.selectedProductCurrentIndex]
                    .products;
            if (this.search != null && this.search.length > 2) {
                var i;
                this.$store.state.categories[
                    this.selectedProductCurrentIndex
                ].products = [];
                for (i = 0; i < products.length; i++) {
                    if (
                        products[i].name
                            .toLowerCase()
                            .search(this.search.toLowerCase()) > -1
                    ) {
                        this.$store.state.categories[
                            this.selectedProductCurrentIndex
                        ].products.push(products[i]);
                    }
                }
                if (
                    this.$store.state.categories[
                        this.selectedProductCurrentIndex
                    ].products.length == 0
                ) {
                    this.error =
                        "There are no products available in your search.";
                    this.refreshProducts();
                }
            } else {
                this.fetchStore();
                this.error = "";
            }
        },
        refreshProducts() {
            setTimeout(() => {
                this.fetchStore();
                this.search = "";
                this.error = "";
            }, 2000);
        },
        // selectCategory(categoryId, primary) {
        //   this.showAllProducts = primary;
        //   this.$store.state.selectedStoreCategoryId = categoryId;
        //   this.productsToShow = 5;
        //   var i;
        //   for (i = 0; i < this.takeCategories.length; i++) {
        //     if (this.takeCategories[i].id == categoryId) {
        //       this.takeCategories[i].bgColor = "#36D5F2";
        //       this.selectedCategoryId = this.takeCategories[i].id;
        //       this.selectedProductCurrentIndex = i;
        //     } else {
        //       this.takeCategories[i].bgColor = "#FFFFFF";
        //     }
        //   }
        //   this.search = "";
        // },
        fetchStoreByCategoryId(categoryId, page = 1) {
            const token = localStorage.getItem("children_token");
            const userId = localStorage.getItem("children_user_id");
            const familyIdChk = localStorage.getItem("children_family_id");
            this.loading = true;
            axios
                .get(
                    `/children/stores?id=1&&userId=${userId}&token=${token}&family_id_check=${familyIdChk}&category_id=${categoryId}&page=${page}`
                )
                .then((response) => {
                    const { statusCode } = response.data;
                    if (status.success(statusCode)) {
                        this.loading = false;
                        this.isLoadingMore = false;
                        const paginatorObject = {
                            total_pages:
                                response.data.data[0].categories[0]
                                    .total_pages_products,
                            current_page:
                                response.data.data[0].categories[0]
                                    .current_pages,
                        };
                        this.pagination = paginatorObject;
                        // this.storeProducts =
                        //     response.data.data[0].categories[0].products;
                        response.data.data[0].categories[0].products.forEach(
                            (product) => {
                                this.storeProducts.push(product);
                            }
                        );
                    } else if (status.invalidToken(statusCode)) {
                        this.deleteStorage();
                    } else if (status.error(statusCode)) {
                        this.TOAST(
                            "Something went wrong! Please contact our support team."
                        );
                    }
                })
                .catch((error) => {
                    this.TOAST(
                        "Something went wrong! Please contact our support team."
                    );
                    throw error;
                });

            this.busy = false;
        },
        selectCategory(categoryId) {
            this.selectedCategoryId = categoryId;
            this.storeProducts = [];
            this.scrolledToBottom = true;
            this.fetchStoreByCategoryId(categoryId);
        },
        isSelected(category_id) {
            if (category_id == this.selectedCategoryId) return true;
            return false;
        },
    },
    computed: {
        ...mapGetters([
            "getSpendWalletBalance",
            "takeCategories",
            "getProductsFirstCategory",
            "getProductsFirstCategoryId",
            "getSaveWalletBalance",
            "childName",
            "brandsGetter",
            "allStoreProductsGetter",
        ]),
        resultQuery() {
            var brandId = this.selectedBrand;
            var products = this.showAllProducts
                ? this.allStoreProductsGetter
                : this.$store.state.categories[this.selectedProductCurrentIndex]
                      .products;

            if (this.search) {
                return products.filter((item) => {
                    if (brandId === 0) {
                        return this.search
                            .toLowerCase()
                            .split(" ")
                            .every((v) => item.name.toLowerCase().includes(v));
                    } else {
                        return this.search
                            .toLowerCase()
                            .split(" ")
                            .every(
                                (v) =>
                                    item.name.toLowerCase().includes(v) &&
                                    item.brand_id === brandId
                            );
                    }
                });
            } else {
                if (brandId === 0) {
                    return products;
                } else {
                    return products.filter(function (person) {
                        return person.brand_id === brandId;
                    });
                }
            }
        },
        filteredBrands() {
            var brandId = this.selectedBrand;

            if (Object.keys(this.takeCategories).length > 0) {
                if (brandId === 0) {
                    return this.takeCategories[this.selectedProductCurrentIndex]
                        .products;
                } else {
                    return this.takeCategories[
                        this.selectedProductCurrentIndex
                    ].products.filter(function (person) {
                        console.log(person);
                        return person.brand_id === brandId;
                    });
                }
            } else {
                return false;
            }
        },
    },
    watch: {
        "$store.state.categories"(newCategories) {
            this.$store.state.categories = newCategories;
        },
    },
    async created() {
        const user = {
            userId: localStorage.getItem("children_user_id"),
            token: localStorage.getItem("children_token"),
            family_id_check: localStorage.getItem("children_family_id"),
        };

        return await Promise.all([
            this.fetchProfile(),
            this.setupUser(user),
            this.fetchStore(),
            this.fetchOcassions(),
            this.fetchBrandsAction(),
        ]).then(() => {
            // console.log(this.allStoreProductsGetter)
            // console.log(
            //   this.takeCategories.filter((category) => {
            //     this.all_products = category.products;
            //     console.log(this.all_products);
            //   })
            // );
            this.categories = this.takeCategories;
            this.selectedCategoryId = localStorage.getItem("firstCategory");
            if(this.takeCategories.length > 0) {
                this.selectCategory(this.takeCategories[0].id);
            }
            setTimeout(() => {
                this.selectCategory(localStorage.getItem("firstCategory"));
            }, 500);
        });
    },
};
</script>

<style lang="scss" scoped>
.searchBar {
    background-color: #fff;
    width: 100%;
    // border: 1px solid #e0e0e0;
    border-radius: 10px;
    outline: none;
    color: #444;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    input {
        width: 90%;
        padding: 15px;
        border: none;
        background-color: #fff;
        outline: none;
    }

    .fa-search {
        width: 10%;
        padding-right: 8px;
    }
}

.list_ul {
    list-style: none;
    // overflow: hidden;
}

.list-item {
    position: relative;
    // height: 5rem;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    margin-bottom: 8px;
    border-radius: 15px;
}

.list-item[data-type="0"] {
    transform: translate3d(0, 0, 0);
}

.list-item[data-type="1"] {
    transform: translate3d(-5rem, 0, 0);
}

.list-box {
    padding: 1.2rem;
    background: #fff;
    border-radius: 15px;
    height: 100%;
    display: flex;
    align-items: center;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    justify-content: flex-end;
}

.list-item .list-img {
    display: block;
    width: 2.5rem;
    height: 2.5rem;
}

.list-item .list-content {
    padding: 0.25rem 0 0.25rem 0.5rem;
    position: relative;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    overflow: hidden;
}

.list-item .title {
    display: block;
    overflow: hidden;
    font-size: 0.75rem;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
}

.list-item .tips {
    display: block;
    overflow: hidden;
    font-size: 0.6rem;
    color: #999;
    line-height: 1rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 250px;
}

.list-item .time {
    display: block;
    font-size: 0.6rem;
    position: absolute;
    right: 0;
    top: 0.25rem;
    color: #666;
}

.list-item .delete {
    width: 5rem;
    height: 100%;
    // background: #ff4949;
    font-size: 0.85rem;
    color: var(--main);
    text-align: center;
    position: absolute;
    top: 0;
    right: -5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loading {
    text-align: center;
    position: absolute;
    color: #fff;
    z-index: 9;
    background: #222;
    padding: 8px 18px;
    border-radius: 5px;
    left: calc(50% - 45px);
    top: calc(50% - 18px);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}
</style>
